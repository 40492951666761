import { Form, Link, NavLink, useLocation, useNavigate, useNavigation } from '@remix-run/react';
import { HomeIcon, MessageCircleIcon, NewspaperIcon, SearchIcon } from 'lucide-react';
import { useCallback, useEffect } from 'react';
import { GoogleProfile } from 'remix-auth-google';
import { CurrentTime } from '~/components/CurrentTime';
import { TopicNav } from '~/components/topic/TopicNav';
import { cn } from '~/lib/utils';

export default function AppLayout({
  today,
  user,
  children,
}: {
  today: string;
  user: GoogleProfile | null;
  children: React.ReactNode;
}) {
  const navigation = useNavigation();
  const navigate = useNavigate();

  useGradient();

  const captureAnchorClicks = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLAnchorElement;

    if (target.tagName !== 'A') return;

    const href = target.getAttribute('href');

    if (!href) return;

    if (href.startsWith('https://beta.exposit.ai/')) {
      event.preventDefault();
      event.stopPropagation();
      const url = href.replace('https://beta.exposit.ai', '');
      navigate(url);
    } else if (href.startsWith('https://') || href.startsWith('http://')) {
      event.preventDefault();
      event.stopPropagation();
      open(href, '_blank', 'noopener,noreferrer');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className="flex min-h-[100dvh] flex-col text-gray-12" onClick={captureAnchorClicks}>
      <header className="flex items-center justify-between px-2 sm:px-5">
        <nav className="mt-1 flex w-1/2 select-none items-center gap-4">
          {user && <TopicNav user={user} />}
          <Search />
        </nav>
        <div className="select-none text-center font-mono">
          <h1 className="mt-4 translate-x-1 text-2xl font-semibold uppercase tracking-[0.5rem]">
            <Link to={user ? '/home' : '/'}>Exposit</Link>
          </h1>
          <CurrentTime today={today} />
        </div>
        <div className="flex w-1/2 select-none items-center justify-end font-headline text-xs font-semibold">
          {navigation.state === 'loading' && <span className="mr-10">Loading...</span>}
          {user ? (
            <Link to="/profile" className="flex items-center gap-2">
              <span className="hidden sm:block">{user?.displayName}</span>
              <img
                src={user?.photos[0].value}
                alt={user?.displayName}
                className="h-5 w-5 rounded-full"
                onError={(e) => e.currentTarget.classList.add('error')}
              />
            </Link>
          ) : (
            <Link to="/login">Login</Link>
          )}
        </div>
      </header>

      {children}

      <Footer user={user} />
    </div>
  );
}

export function Search() {
  const location = useLocation();
  return (
    <>
      <Link to="/unified" className="sm:hidden">
        <SearchIcon size={20} />
      </Link>
      <Form
        key={location.pathname}
        action="/unified"
        className={cn(
          `relative mr-10 hidden flex-grow rounded-md border border-transparent py-1 focus-within:border-brand-gray2 sm:block`,
          {
            'hidden sm:hidden': location.pathname.startsWith('/unified') || location.pathname.startsWith('/search'),
          }
        )}
      >
        <SearchIcon size={20} className="absolute left-1 top-1/2 z-10 -translate-y-1/2" />
        <input
          type="text"
          name="text"
          placeholder="Search"
          className="relative z-20 w-full bg-transparent pl-8 placeholder:text-transparent autofill:bg-transparent focus:outline-none focus:placeholder:text-brand-gray2"
        />
      </Form>
    </>
  );
}

function useGradient() {
  useEffect(() => {
    function handleMove({ x, y }: { x: number; y: number }) {
      requestAnimationFrame(() => {
        document.body.style.setProperty('--x', x.toString());
        document.body.style.setProperty('--y', y.toString());
      });
    }
    window.addEventListener('pointermove', handleMove);

    return () => {
      window.removeEventListener('pointermove', handleMove);
    };
  }, []);
}

const footerbg = `radial-gradient(
  80vw circle at calc(var(--x) * 1px) 10%,
  rgba(var(--rainbow-stop-1) / var(--rainbow-opacity)) 0%,
  rgba(var(--rainbow-stop-2) / var(--rainbow-opacity)) 52.26%,
  rgba(var(--rainbow-stop-3) / var(--rainbow-opacity)) 102.65%
)`;

function Footer({ user }: { user: GoogleProfile | null }) {
  return (
    <>
      {/* Desktop and Mobile footer */}
      <footer style={{ background: footerbg }} className="relative z-20">
        <div className="mx-auto w-full max-w-screen-xl px-5 py-20 font-mono text-sm dark:text-white">
          <nav className="grid gap-2">
            {user && (
              <Link to="/home" className="hover:underline">
                Home
              </Link>
            )}
            <Link to="/topics" className="hover:underline">
              All topics
            </Link>
            <Link to="/search" className="hover:underline">
              Search
            </Link>
          </nav>
        </div>
      </footer>

      {/* Mobile sticky bottom navigation */}
      <nav
        className="fixed bottom-0 left-0 right-0 z-50 box-content flex justify-around bg-white py-2 shadow-lg dark:bg-gray-2 sm:hidden"
        style={{ paddingBottom: 'max(1rem, env(safe-area-inset-bottom))' }}
      >
        <NavLink
          to="/home"
          className={({ isActive }) =>
            cn(`flex flex-col items-center text-xs`, { 'font-bold text-brand-navy': isActive })
          }
        >
          <HomeIcon size={24} />
          <span>Home</span>
        </NavLink>
        <NavLink
          to="/digest"
          className={({ isActive }) =>
            cn(`flex flex-col items-center text-xs`, { 'font-bold text-brand-navy': isActive })
          }
        >
          <NewspaperIcon size={24} />
          <span>Digest</span>
        </NavLink>
        <NavLink
          to="/chat"
          className={({ isActive }) =>
            cn(`flex flex-col items-center text-xs`, { 'font-bold text-brand-navy': isActive })
          }
        >
          <MessageCircleIcon size={24} />
          <span>Chat</span>
        </NavLink>
      </nav>
    </>
  );
}
