import { Link, NavLink, useFetcher } from '@remix-run/react';
import { cx } from 'class-variance-authority';
import { BookmarkIcon, MenuIcon, PanelLeftCloseIcon, PlusIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { GoogleProfile } from 'remix-auth-google';
import { useOnClickOutside } from '~/hooks/use-on-click-outside';
import { ScrollArea } from '../ui/scroll-area';

type SubscriptionNavItem = {
  id: string;
  title: string;
  order: number;
  slug: string;
  topic_id: string;
  owner_user_id: string;
};

export function TopicNav({ user }: { user: GoogleProfile | null }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const fetcher = useFetcher<{ subscriptions: SubscriptionNavItem[] }>();

  useEffect(() => {
    fetcher.load('/api/subscriptions/nav');

    function closeOnEsc(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    window.addEventListener('keydown', closeOnEsc);
    return () => window.removeEventListener('keydown', closeOnEsc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <div className={`h-5`}>
      <button aria-label="Open navigation menu" onClick={() => setOpen(true)}>
        <MenuIcon size={20} />
      </button>
      <div
        ref={ref}
        className={cx(
          'fixed inset-y-0 left-0 z-50 w-64 border-r border-brand-gray1 bg-white font-headline dark:bg-gray-1',
          '-translate-x-full transition-transform duration-300 ease-in-out',
          {
            'translate-x-0': open,
          }
        )}
      >
        <div className={`mt-10 flex flex-col font-semibold`}>
          <Link to="/" className={`flex gap-2 border-b border-brand-gray1 px-3 pb-2 text-sm`}>
            <img src={user?.photos[0].value} alt={user?.displayName} className={`h-5 w-5 rounded-full`} />
            <h2>My Homepage</h2>
          </Link>
          <ScrollArea className={`h-[calc(100dvh-160px)]`}>
            <Link to="/bookmarks" className={`text-md flex gap-2 border-b border-brand-gray1 px-3 pt-2`}>
              <BookmarkIcon size={16} />
              <span>Bookmarks</span>
            </Link>
            {fetcher.data?.subscriptions.map((item) => (
              <TopicNavLink
                to={item.owner_user_id ? `/u/${item.owner_user_id}/${item.slug}` : `/topics/${item.slug}`}
                key={item.id}
              >
                {item.title}
              </TopicNavLink>
            ))}
          </ScrollArea>
          <TopicNavLink to="/topics" className="border-t text-brand-navy">
            <span>Explore topics</span>
          </TopicNavLink>
          <TopicNavLink to="/topics/new" className="text-brand-navy">
            <PlusIcon size={16} />
            <span>Add topic</span>
          </TopicNavLink>
        </div>
        <button
          aria-label="Close navigation menu"
          onClick={() => setOpen(false)}
          className={`transition-color absolute right-0 top-0 p-2 text-brand-gray2 hover:text-brand-black`}
        >
          <PanelLeftCloseIcon size={16} />
        </button>
      </div>
    </div>
  );
}

function TopicNavLink({ to, children, className = '' }: { to: string; children: React.ReactNode; className?: string }) {
  return (
    <NavLink
      end
      className={({ isActive }) =>
        cx(
          `flex items-center gap-1 px-3 py-2 first:mt-2 sm:hover:bg-brand-gray1`,
          {
            'bg-brand-gray1': isActive,
          },
          className
        )
      }
      to={to}
    >
      {children}
    </NavLink>
  );
}
