import { ClientOnly } from 'remix-utils/client-only';
import { cn } from '~/lib/utils';

export function CurrentTime({ today, className = '' }: { className?: string; today: string }) {
  return (
    <ClientOnly
      fallback={
        <p className={cn(`whitespace-nowrap font-mono text-xs text-brand-gray3`, className)}>
          {today} at <span className={`opacity-50`}>00:00:00 AM</span>
        </p>
      }
    >
      {() => (
        <p className={cn(`whitespace-nowrap font-mono text-xs text-brand-gray3`, className)}>
          {new Date().toLocaleTimeString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
        </p>
      )}
    </ClientOnly>
  );
}
